function Impressum() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 mt-5">
                    <h1>Impressum</h1>
                    <h6>Anbieterkennzeichnung und Informationen gemäß §§ 5 Telemediengesetz (TMG), 55 Absatz 1 und Absatz 2 Rundfunkstaatsvertrag(RfStV)</h6>
                    <p>‍</p>
                    <h3>Firma</h3>
                    <p><strong>ASX Application Solution Experts GmbH</strong><br></br>Maybachstraße 14<br></br>67269 Grünstadt<br></br>Deutschland</p>
                    <p>‍</p>
                    <h4>Rechtsform</h4>
                    <p>Gesellschaft mit beschränkter Haftung (GmbH)</p>
                    <p>‍</p>
                    <h4>Vertretungsberechtigte Geschäftsführer</h4>
                    <p>Gunter Seidenspinner, Sven Seidenspinner</p>
                    <p>‍</p>
                    <h4>Handelsregister</h4>
                    <p>Amtsgericht Ludwigshafen</p>
                    <p>‍</p>
                    <h4>Handelsregisternummer<strong> </strong></h4>
                    <p><strong>‍</strong>‍HRB 68739</p>
                    <p>‍</p>
                    <h4>Umsatzsteueridentifikationsnummer nach § 27a des<br></br>Umsatzsteuergesetzes (UStG)</h4>
                    <p><strong>‍</strong>DE358978707</p>
                    <p>‍</p>
                    <h4>Verantwortliche gemäß § 55 Absatz 2 RfStV</h4>
                    <p><strong>‍</strong>‍Gunter Seidenspinner und Sven Seidenspinner<br></br>Geschäftsführer ASX Application Solution Experts GmbH<br></br>Maybachstraße 14 <br></br>67269 Grünstadt <br></br>Deutschland</p>
                    <p>‍</p>
                    <h4>Markenrechtlicher Hinweis</h4>
                    <p><strong>‍</strong>Alle auf dieser Website genannten bzw. gezeigten Marken oder Warenzeichen sind eingetragene Marken oder eingetragene Warenzeichen ihrer jeweiligen Eigentümer und ggf. nicht gesondert gekennzeichnet. Aus dem Fehlen der Kennzeichnung kann nicht geschlossen werden, dass es sich bei einem Begriff oder einem Bild nicht um eine eingetragene Marke oder ein eingetragenes Warenzeichen handelt. ZurBenutzung von Namen und beschreibenden Angaben wird ergänzend auf § 23Markengesetz (MarkenG) hingewiesen.</p>
                    <p>‍</p>
                    <h4>Kontaktdaten des Anbieters: </h4>
                    <p><strong>‍</strong>Telefon: + 49 (0) 6359 93690<strong><br></br>‍</strong>E-Mail: info@asx-gmbh.com<br></br>Internet: <a href="https://www.asx-gmbh.com">www.asx-gmbh.com</a>
                    </p>
                    <p>‍</p>
                </div>
            </div>
        </div>
    );
  }
  
  export default Impressum;