import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./styles/main.scss";
import Datenschutz from './components/Datenschutz';
import Homepage from './components/Homepage';
import Impressum from './components/Impressum';
import Header from "./components/Header";
import Footer from "./components/Footer";
import { CookieConsent } from "./components/CookieConsent";

const cookieChange = (status) => {
  if (status != null) {
      if (status.analytics) {
      }
  }
}

function App() {
  return (
    <BrowserRouter>
      <Header />
      <div className="sections">
        <Routes>
          <Route path='/' Component={Homepage} />
          <Route path='/datenschutz' Component={Datenschutz} />
          <Route path='/privacy' Component={Datenschutz} />
          <Route path='/impressum' Component={Impressum} />
          <Route path='/imprint' Component={Impressum} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </div>
      <Footer />
      <CookieConsent onChange={cookieChange}/>
    </BrowserRouter >
  );
}

export default App;
