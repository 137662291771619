import CampaignCreators from "../images/campaign-creators.jpg";
import Bullet from "../images/bullet.png";
import { useEffect } from 'react';
import { animateScroll, scroller } from 'react-scroll';
import { Fade } from "react-awesome-reveal";

function Homepage() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);

        if (hash) {
            scroller.scrollTo(hash, {
                duration: 1000,
                delay: 0,
                smooth: true,
            });
        } else {
            animateScroll.scrollToTop();
        }
    }, []);

    return (
        <>
            <section className="section section-intro d-flex align-items-center" id="intro">
                <div className="container">
                    <Fade>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 mb-md-0 mb-5">
                                <h2>ERP<br></br><b>Consulting</b></h2>
                                <p className="section-intro-text">Wir unterstützen und beraten Sie bei der Zentralisierung und Optimierung Ihrer Geschäftsprozesse</p>
                            </div>
                            <div className="col-12 col-md-6 offset-md-1">
                                <img src={CampaignCreators} alt={CampaignCreators} className="img-fluid" />
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
            <section className="section section-services" id="solutions">
                <div className="container">
                    <Fade>
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="section-header"><b>Gemeinsam Lösungen entwickeln</b></h2>
                                <div className="section-list">
                                    <div className="section-list-item">
                                        <img src={Bullet} alt={Bullet} height="30" />
                                        <p>Wir analysieren Ihre internen Abläufe, erkennen und beurteilen Vorgänge und zeigen Potenziale zur Optimierung auf.</p>
                                    </div>
                                    <div className="section-list-item">
                                        <img src={Bullet} alt={Bullet} height="30" />
                                        <p>Wir begleiten Sie auf Ihrem Weg der Digitalisierung, mit der nachhaltigen Implementierung neuer Möglichkeiten in microtech büro+.</p>
                                    </div>
                                    <div className="section-list-item">
                                        <img src={Bullet} alt={Bullet} height="30" />
                                        <p>Wir bieten Ihnen einen Full-Service, der Beratung, Implementierung, Schulung und langfristige Betreuung beinhaltet.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
            <section className="section section-cards" id="services">
                <div className="container">
                    <Fade>
                        <div className="col-12 text-center">
                            <h2 className="section-header"><b>Potentiale ausschöpfen</b></h2>
                        </div>
                        <div className="col-12">
                            <div className="row row-cols-2 g-3">
                                <div className="col-12 col-md-6">
                                    <div className="section-card">
                                        <div className="section-card-header">
                                            <h3>Optimierung</h3>
                                        </div>
                                        <div className="section-card-body">
                                            <p>Wir optimieren nachhaltig Ihr bestehendes System und helfen Ihnen dabei Ihre bestehenden Prozesse effektiver zu gestalten.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="section-card orange">
                                        <div className="section-card-header">
                                            <h3>Implementierung</h3>
                                        </div>
                                        <div className="section-card-body">
                                            <p>Wir implementieren neue Prozesse anhand Ihrer Wünsche und begleiten Sie auf dem Weg zur Digitalisierung und Automatisierung.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="section-card green">
                                        <div className="section-card-header">
                                            <h3>Kompletteinführung</h3>
                                        </div>
                                        <div className="section-card-body">
                                            <p>Wir führen Kompletteinführungen durch, von der Übernahme Ihrer bestehenden Prozesse bis hin zur Datenübernahme aus Ihrem bestehenden System.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="section-card yellow">
                                        <div className="section-card-header">
                                            <h3>Schulung</h3>
                                        </div>
                                        <div className="section-card-body">
                                            <p>Wir schulen Ihre Mitarbeiter auf Wunsch in allen Bereichen der Software und begleiten Sie ständig bei Fragen oder neuen Ideen.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
            <section className="section section-contact" id="contact">
                <div className="container">
                    <Fade>
                        <div className="row">
                            <div className="col-12 text-center text-md-start">
                                <h2><b>Kontakt</b></h2>
                                <p className="mb-5">
                                    ASX Application Solution Experts GmbH<br></br>
                                    E-Mail: <a href="mailto:info@asx-gmbh.com">info@asx-gmbh.com</a><br></br>
                                    Telefon: <a href="tel:+4963599369272">+49 6359 9369 272</a><br></br>
                                    Maybachstraße 14<br></br>
                                    67269 Grünstadt<br></br>
                                </p>
                                <a href="mailto:info@asx-gmbh.com" className="btn btn-primary btn-block">Jetzt Kontakt aufnehmen!</a>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
        </>
    );
}

export default Homepage;