function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="d-flex h-100 align-items-md-end justify-content-md-end">
                    <a href="/impressum" className="me-4">Impressum</a>
                    <a href="/datenschutz">Datenschutz</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;