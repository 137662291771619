import React, { useState, useEffect } from 'react';
import UseBreakpoint from './UseBreakpoint';

const CookieConsent = ({ onChange }) => {
    const { isDown } = UseBreakpoint();
    const [showConsent, setShowConsent] = useState(false);
    const [essentialChecked, setEssentialChecked] = useState(true);
    const [analyticsChecked, setAnalyticsChecked] = useState(false);

    useEffect(() => {
        const consentStatus = localStorage.getItem('cookieConsent');
        if (consentStatus === null) {
            setShowConsent(true);

            if (onChange) {
                onChange(null);
            }
        } else {
            const consentOptions = JSON.parse(consentStatus);
            setEssentialChecked(consentOptions.essential);
            setAnalyticsChecked(consentOptions.analytics ?? false);

            if (onChange) {
                onChange(JSON.parse(consentStatus));
            }
        }
    }, []);

    const handleConsentChange = () => {
        const consentOptions = {
            essential: essentialChecked,
            analytics: analyticsChecked,
        };
        localStorage.setItem('cookieConsent', JSON.stringify(consentOptions));
        setShowConsent(false);
        if (onChange) {
            onChange(consentOptions);
        }
    };

    const handleAcceptAll = () => {
        const consentOptions = {
            essential: true,
            analytics: true,
        };
        localStorage.setItem('cookieConsent', JSON.stringify(consentOptions));
        setShowConsent(false);
        setAnalyticsChecked(true);
        if (onChange) {
            onChange(consentOptions);
        }
    };

    if (!showConsent) {
        return null;
    }

    return (
        <>
            {isDown('xl') ? (
                <div className='c-cookieConsent'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="row">
                                    <div className="col-12">
                                        <p className='mb-2 mb-md-5'>
                                            ASX verwendet Cookies, um die Nutzung der Website zu ermöglichen und zu verbessern.
                                            Weitere Informationen dazu finden Sie in unserer Cookie-Richtlinie.
                                        </p>
                                    </div>
                                    <div className='col-12'>
                                        <div className="row align-items-center justify-content-between">
                                            <div className='col-12 col-md-6 d-flex mb-5 mb-md-0'>
                                                <div className="form-check me-4">
                                                    <input className="form-check-input" type="checkbox" id="essentialCookies" checked={essentialChecked} onChange={() => { }} disabled />
                                                    <label className="form-check-label" htmlFor="essentialCookies">
                                                        Essentials
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="analyticsCookies" checked={analyticsChecked} onChange={e => setAnalyticsChecked(e.target.checked)} />
                                                    <label className="form-check-label" htmlFor="analyticsCookies">
                                                        Analytics
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6 d-flex justify-content-end'>
                                                <button className='btn btn-secondary me-3' onClick={handleConsentChange}>Nur Notwendige</button>
                                                <button className='btn btn-primary' onClick={handleAcceptAll}>Alle Akzeptieren</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='c-cookieConsent'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="d-flex align-items-center">
                                    <div className="flex-md-grow-1">
                                        <p>
                                            ASX verwendet Cookies, um die Nutzung der Website zu ermöglichen und zu verbessern.
                                            Weitere Informationen dazu finden Sie in unserer Cookie-Richtlinie.
                                        </p>
                                        <div className="d-flex">
                                            <div className="form-check me-4">
                                                <input className="form-check-input" type="checkbox" id="essentialCookies" checked={essentialChecked} onChange={() => { }} disabled />
                                                <label className="form-check-label" htmlFor="essentialCookies">
                                                    Essentials
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="analyticsCookies" checked={analyticsChecked} onChange={e => setAnalyticsChecked(e.target.checked)} />
                                                <label className="form-check-label" htmlFor="analyticsCookies">
                                                    Analytics
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <button className='btn btn-secondary me-3' onClick={handleConsentChange}>Nur Notwendige</button>
                                        <button className='btn btn-primary' onClick={handleAcceptAll}>Alle Akzeptieren</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const isApproved = (type) => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
        const consentOptions = JSON.parse(consent);
        return consentOptions[type];
    }
    return false;
};

export { CookieConsent, isApproved };