import Logo from "../images/ASX-Logo.svg";
import Hamburger from 'hamburger-react';
import { useState } from 'react';

function Header() {
    const [isOpen, setOpen] = useState(false);
    return (
        <header className="menu">
            <div className="container">
                <div className="d-flex align-items-center py-3">
                    <div className="me-auto">
                        <a href="/">
                            <img src={Logo} alt={Logo} className="menu-logo" />
                        </a>
                    </div>
                    <div className="d-none d-md-block">
                        <div className="menu-links">
                            <a href="/#solutions">Lösungen</a>
                            <a href="/#services">Leistungen</a>
                            <a href="/#contact">Kontakt</a>
                        </div>
                    </div>
                    <div className="d-block d-md-none">
                        <Hamburger toggled={isOpen} toggle={setOpen} />
                    </div>
                </div>
                <div className={`menu-popup ${isOpen ? " menu-popup-open" : ""}`}>
                    <div className="menu-links">
                        <a href="/#solutions">Lösungen</a>
                        <a href="/#services">Leistungen</a>
                        <a href="/#contact">Kontakt</a>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;