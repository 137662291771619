import { useState, useEffect } from 'react';

const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

const UseBreakpoint = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isUp = (breakpoint) => {
        return width >= breakpoints[breakpoint];
    };

    const isDown = (breakpoint) => {
        return width < breakpoints[breakpoint];
    };

    return { isUp, isDown };
};

export default UseBreakpoint;